import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import { navigate, Link } from 'gatsby'
import querystring from 'query-string'
import moment from 'moment'
import { toast } from 'react-toastify'

import './receptor-details.scss'

import Layout from '../components/Layout'
import jwt, { isBrowser, rolesHasOneOf } from '../components/Auth'
import Checkbox from '../components/Checkbox'
import Radio from '../components/Radio'
import { getReceptorEvaluatios } from '../services/api/receptor-evaluations'
import { getReceptorActions } from '../services/api/receptor-actions'

import {
  getReceptorDetails,
  putReceptorDetails,
  postReceptorDetails
} from '../services/api/receptor-details'

import { getRemission, putRemission } from '../services/api/remissions'
import { getChangeLogs } from '../services/api/change-logs'
import { getFromPivot, putToPivot } from '../services/api/pivots'
import { Context } from '../Context'

export default function receptorDetailsPage (props) {
  const { isAuth } = useContext(Context)

  if (!isAuth) {
    if (isBrowser()) {
      navigate('/dashboard/')
    }
    return <Layout withoutHeader>Cargando...</Layout>
  }

  const queryParams = querystring.parse(props.location.search)
  const [panel, setPanel] = useState('executed_action')
  const [canEdit, setCanEdit] = useState(false)
  const [receptorDetails, setReceptorDetails] = useState({})
  const [remission, setRemission] = useState({})
  const [changeLogs, setChangeLogs] = useState({ logs: [], authors: [] })

  const [formValues, setFormValues] = useState({
    executed_action: '',
    tracing: '',
    receptor_evaluation_id: 0,
    process: ''
  })

  // datasets
  const [receptorEvaluations, setReceptorEvaluations] = useState([])
  const [receptorActions, setReceptorActions] = useState([])

  // selected
  const [selectedReceptorActions, setSelectedReceptorActions] = useState([])

  useEffect(() => {
    // get receptor_evaluations
    getReceptorEvaluatios()
      .then(res =>
        setReceptorEvaluations(
          res.data.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
        )
      )
      .catch(err => console.dir(err))

    // get receptor_actions
    getReceptorActions()
      .then(res => setReceptorActions(res.data))
      .catch(err => console.dir(err))
  }, [])

  // get remission para conocer el estado actual (abierto/cerrado)
  useEffect(() => {
    if (!queryParams.remission_id) return

    getRemission(queryParams.remission_id)
      .then(res => {
        if (res.status === 200) {
          const remi = res.data.remissions[0]

          setRemission(remi)

          // si la remisión aún está abierta
          if (remi.status && remi.boss_check) {
            // si es director de la fuerza ó admin
            if (rolesHasOneOf([5, 1])) {
              setCanEdit(true)
            } else {
              // si jwt es el receptor
              if (jwt().payload.user_id === remi.receiver) {
                setCanEdit(true)
              }
            }
          }
        }
      })
      .catch(err => console.error(err.response))
  }, [])

  // get receptor_details
  useEffect(() => {
    if (!queryParams.remission_id) return

    let receptorDetailsID = 0
    let newLogs = {
      logs: [],
      authors: []
    }

    getReceptorDetails({ remission_id: queryParams.remission_id })
      .then(res => {
        setReceptorDetails(res.data)

        if (res.data.details.length) {
          setFormValues(res.data.details[0])
          receptorDetailsID = res.data.details[0].id
        }
      })

      // get receptor_action_receptor_detail pivote
      .then(() => {
        if (!receptorDetailsID) return

        getFromPivot({
          action: 'receptor_action_receptor_detail',
          receptor_detail_id: receptorDetailsID
        })
          .then(res => {
            setSelectedReceptorActions(res.data.map(i => parseInt(i.receptor_action_id), 10))
          })
      })

      // get change_logs of receptor_details
      .then(() => {
        if (!receptorDetailsID) return

        return getChangeLogs({
          table: 'receptor_details',
          id: receptorDetailsID
        })
          .then(res => {
            if (res.status === 200) {
              newLogs = res.data
            }
          })
      })

      // get change_logs receptor_action_receptor_detail
      .then(() => {
        if (!receptorDetailsID) return

        return getChangeLogs({
          table: 'receptor_action_receptor_detail',
          receptor_detail_id: receptorDetailsID
        })
          .then(res => {
            if (res.status === 200) {
              const authors = res.data.authors.filter(i => {
                const exists = newLogs.authors.findIndex(a => a.id === i.id)
                // si no existe aún
                return exists === -1
              })

              // agrega los registros de logs a los ya existentes y ordena todo el conjunto de mator a menor usando created_at
              const orderedLogs = [
                ...newLogs.logs,
                ...res.data.logs
              ].sort((a, b) => {
                if (a.created_at > b.created_at) {
                  return -1
                } else if (a.created_at < b.created_at) {
                  return 1
                }
                return 0
              })

              newLogs = {
                ...newLogs,
                logs: orderedLogs,
                authors: [
                  ...newLogs.authors,
                  ...authors
                ]
              }
            }
          })
      })

      .then(() => {
        setChangeLogs(newLogs)
      })

      .catch(err => console.dir(err))
  }, [])

  // send receptor_action_receptor_detail pivote
  function putActionsPivote () {
    if (!selectedReceptorActions.length) return

    putToPivot(
      {
        action: 'receptor_action_receptor_detail'
      },
      {
        values: selectedReceptorActions.map(i => {
          return {
            receptor_detail_id: receptorDetails.details[0].id,
            receptor_action_id: i
          }
        })

      }
    )
  }

  // envía los datos al backend
  function submitReceptorDetails (e) {
    e.preventDefault()

    // receptor_details
    const data = {
      ...formValues,
      author: jwt().payload.user_id
    }

    let request = null

    if (data.id) {
      delete data.updated_at
      delete data.created_at
      delete data.remission_id
      delete data.author
      request = putReceptorDetails(data)
    } else {
      data.remission_id = remission.id
      request = postReceptorDetails(data)
    }

    request
      .then(() => {
        if (!remission.status) {
          // PUT para cerrar la remisión
          return putRemission(remission).then(res => {
            setCanEdit(false)
            toast.info('Remisión cerrada')
          })
        }
      })
      .then(() => {
        toast.success('Cambios guardados')
      })
      .then(() => {
        putActionsPivote()
      })
      .catch(err => {
        console.error(err.response)
        toast.error(err.response.data.message)
      })
  }

  function handleChange (e) {
    if (!canEdit) return

    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  // controla el estado de la remisión
  function handleStatusChange (e) {
    if (e.target.checked) {
      setRemission({
        ...remission,
        status: e.target.value === '1'
      })
    }
  }

  // retorna el nombre humano del campo en change_logs
  function getFieldName (field) {
    if (!field) return '-'

    const name = field.split('.')[1]

    switch (name) {
      case 'executed_action':
        return 'Acción tomada'
      case 'tracing':
        return 'Seguimiento'
      case 'receptor_evaluation_id':
        return 'Evaluación'
      case 'process':
        return 'Procedimiento'
      case 'receptor_action_id':
        return 'Acción a seguir'
      case 'action_to_follow':
        // mantiene compatibilidad con el historial antes de la existencia del pivote receptor_action_receptor_detail
        return 'Acción a seguir'
      default:
        return '-'
    }
  }

  // retorna el nombre completo del autor
  function getAuthorName (id) {
    const author = changeLogs.authors.find(i => i.id === id)
    if (!author) return '-'
    return author.fullname
  }

  if (!receptorDetails.session) {
    return <div>Cargando...</div>
  }

  return (
    <Layout className='receptor-details-page'>
      <h1>REMISIÓN #{queryParams.remission_id}</h1>

      <div className='content'>
        <div
          style={{ color: 'white', marginBottom: '0.5rem', fontWeight: '700' }}
        >
          REMISIÓN DESDE LDV
        </div>

        <div className='patient'>
          <div>{`${receptorDetails.patient.fullname}`.toUpperCase()}</div>
          <div>{`${receptorDetails.patient.population}`.toUpperCase()}</div>
          <div>
            {`${receptorDetails.patient.force}, ${receptorDetails.patient.hierarchy}, ${receptorDetails.patient.rank}`.toUpperCase()}
          </div>
          <div>
            <Link
              to={`/remit-session/?session_id=${receptorDetails.session.id}`}
              style={{
                color: 'white',
                display: 'inline-block',
                margin: '8px',
                border: '1px solid white',
                padding: '0 4px'
              }}
            >
              Ver remisión
            </Link>
          </div>
        </div>

        <div className='buttons'>
          <button
            className={panel === 'executed_action' ? 'active' : ''}
            onClick={() => setPanel('executed_action')}
          >
            ACCIÓN TOMADA
          </button>

          <button
            className={panel === 'tracing' ? 'active' : ''}
            onClick={() => setPanel('tracing')}
          >
            SEGUIMIENTO
          </button>

          <button
            className={panel === 'process' ? 'active' : ''}
            onClick={() => setPanel('process')}
          >
            PROCEDIMIENTO
          </button>

          <button
            className={panel === 'action_to_follow' ? 'active' : ''}
            onClick={() => setPanel('action_to_follow')}
          >
            ACCIÓN A SEGUIR
          </button>
        </div>

        <div className={panel === 'executed_action' ? '' : 'hidden'}>
          <div className='label'>ACCIÓN TOMADA EJS</div>
          <textarea
            name='executed_action'
            value={formValues.executed_action}
            onChange={handleChange}
          />
        </div>

        <div className={panel === 'tracing' ? '' : 'hidden'}>
          <div className='label'>SEGUIMIENTO</div>
          <textarea
            name='tracing'
            value={formValues.tracing}
            onChange={handleChange}
          />

          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '1rem'
            }}
          >
            <strong style={{ color: 'white' }}>EVALUACIÓN:</strong>
            <div
              style={{ display: 'flex', marginLeft: '0.5rem', width: '100%' }}
            >
              <Select
                isDisabled={!canEdit}
                options={receptorEvaluations}
                onChange={option =>
                  setFormValues({
                    ...formValues,
                    receptor_evaluation_id: option.value
                  })}
                value={receptorEvaluations.find(
                  i => i.value === formValues.receptor_evaluation_id
                )}
                placeholder='Evaluación'
                className='select'
                classNamePrefix='select'
              />
            </div>
          </label>
        </div>

        <div className={panel === 'process' ? '' : 'hidden'}>
          <div className='label'>PROCEDIMIENTO</div>
          <textarea
            name='process'
            value={formValues.process}
            onChange={handleChange}
          />
        </div>

        <div className={panel === 'action_to_follow' ? '' : 'hidden'}>
          <div className='label'>ACCIÓN A SEGUIR</div>
          <div className='actions-to-follow__items'>
            {receptorActions.map(ra => {
              return (
                <label key={ra.id}>
                  <Checkbox
                    checked={selectedReceptorActions.includes(ra.id)}
                    disabled={!canEdit}
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedReceptorActions([
                          ...selectedReceptorActions,
                          ra.id
                        ])
                      } else {
                        setSelectedReceptorActions(
                          selectedReceptorActions.filter(i => i !== ra.id)
                        )
                      }
                    }}
                  />
                  <span>{ra.name}</span>
                </label>
              )
            })}
          </div>
        </div>

        <div className='remission-control'>
          <strong>CONTROL DE REMISIÓN:</strong>

          <div className='options'>
            <label>
              <Radio
                name='status'
                value={1}
                disabled={!canEdit}
                checked={remission.status}
                onChange={handleStatusChange}
              />{' '}
              <span>ACTIVO</span>
            </label>

            <label>
              <Radio
                value={0}
                name='status'
                disabled={!canEdit}
                checked={!remission.status}
                onChange={handleStatusChange}
              />{' '}
              <span>CERRADO</span>
            </label>
          </div>
        </div>

        {changeLogs.logs.length === 0 ? null : (
          <>
            <h2>HISTORIAL DE CAMBIOS</h2>

            <table className='table'>
              <thead>
                <tr>
                  <th>Fecha y hora</th>
                  <th>Usuario</th>
                  <th>Campo</th>
                </tr>
              </thead>
              <tbody>
                {changeLogs.logs.map((row, idx) => (
                  <tr key={idx}>
                    <td>
                      {moment
                        .utc(remission.updated_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                    <td>{getAuthorName(row.author)}</td>
                    <td>{getFieldName(row.name)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {!canEdit ? null : (
          <button
            style={{
              display: 'block',
              margin: '3rem auto 1rem auto',
              padding: '0.25rem 2rem',
              fontWeight: '700',
              fontSize: '1rem'
            }}
            onClick={submitReceptorDetails}
          >
            GUARDAR
          </button>
        )}
      </div>
    </Layout>
  )
}
